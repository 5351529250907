const getAllBrokerForgottenPasswordInputsValid: () => boolean = () => {
  try {
    const emailInput: any = document.getElementById('email');
    const isValidEmail: boolean = getIsValidEmail(emailInput?.value || '');
    const allValid = isValidEmail;

    if (!isValidEmail) setEmailInputError('email');

    if (allValid) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const migrateBrokerForgottenPasswordValuesToNewFormAndReturnSuccess: () => boolean = () => {
  try {
    const emailID: string = 'email';

    const new_emailID: string = 'field_key_email_4';

    const emailMigrated: boolean = relocateEmailInputValueByIdToNewInputByIdAndReturnSuccess(emailID, new_emailID);

    const allMigrated: boolean = emailMigrated;

    if (allMigrated) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const handleBrokerForgottenPasswordSubmission: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const allBrokerForgottenPasswordInputsValid: boolean = getAllBrokerForgottenPasswordInputsValid();
    const loadingContainer: any = document.getElementById('loading-container');

    loadingContainer.classList.add('active');

    if (!allBrokerForgottenPasswordInputsValid) {
      handleBrokerForgottenPasswordSubmissionError();
      return;
    }

    const allMigrated: boolean = migrateBrokerForgottenPasswordValuesToNewFormAndReturnSuccess();

    if (!allMigrated) {
      handleBrokerForgottenPasswordSubmissionError();
      return;
    }

    handleBrokerForgottenPasswordSubmissionSuccess();
  } catch (error) {
    console.log(error);
    handleBrokerForgottenPasswordSubmissionError();
  }
};

const handleBrokerForgottenPasswordSubmissionError: () => void = () => {
  try {
    const loadingContainer: any = document.getElementById('loading-container');
    // const errorContainer: any = document.getElementById('error-container');

    loadingContainer.classList.remove('active');
    // errorContainer.classList.add('active');
  } catch (error) {
    console.log(error);
  }
};

const handleBrokerForgottenPasswordSubmissionSuccess: () => void = () => {
  try {
    const targetForm: any = document.getElementById('form_form-broker-passwordforgotten');
    targetForm.submit();
    // window.location.href = './page-broker-password-sent.html';
  } catch (error) {
    console.log(error);
  }
};
